import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()
import 'stylesheets/haveringsendiass/application.scss'
import 'javascripts/navbar'
import 'javascripts/cms_pages'
import 'javascripts/blog_article_browser'
import 'javascripts/site_searches'
import 'javascripts/was_this_useful'

import Glide, { Controls, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
function ready(callback){
    // in case the document is already rendered
    if (document.readyState!='loading') callback();
    // modern browsers
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    // IE <= 8
    else document.attachEvent('onreadystatechange', function(){
        if (document.readyState=='complete') callback();
    });
  }
ready(function(){
      setTimeout(function(){
        var glide = new Glide('.glide', {
          hoverpause: false,
          perView: 1,
          autoplay:8000,
        }).mount({ Controls, Autoplay, Swipe })
  
        var play = document.querySelector("#js_glide_play");
        var pause = document.querySelector("#js_glide_pause");
  
        play.addEventListener('click', function () {
          glide.play();
        })
  
        pause.addEventListener('click', function () {
          glide.pause();
        })
        
        
        if (window.innerWidth < 767) {
          new Glide('.glide-faq', {
            type: 'carousel',
            hoverpause: false,
            perView: 1,
            autoplay:false,
          }).mount({ Controls, Autoplay, Swipe })
        }
        else {
          new Glide('.glide-faq', {
              type: 'carousel',
              hoverpause: false,
              perView: 3,
              autoplay:false,
              gap: 50,
            }).mount({ Controls, Autoplay, Swipe })
         }

      }, 3000);
    }
)